@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    background-color: rgb(148 163 184);
    /* font-family: "Permanent Marker", cursive; */
    font-weight: 400;
    font-style: normal;
    font-family: "Poetsen One", sans-serif;
    overflow-x: hidden;
  }
}

.blurred-border-bottom::after {
  content: "";
  position: absolute;
  bottom: -20px;
  right: -20px;
  left: -20px;
  height: 40px;
  width: 120%;
  overflow-y: hidden;
  background: gray ;
  box-shadow: 0 10% 10% rgba(0, 0, 0, 0.3);
  filter: blur(8px);
  z-index: 20;
}
.img-hor {
  transform: rotateY(180deg);
}

.img-vert {
  transform: rotateX(180deg);
}

.fade-img {
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 70%,
    transparent 100%
  );
}

.updated-dark .react-tweet-theme {
  --tweet-body-font-size: 0.75rem;
  --tweet-body-line-height: 1rem;
}
